.toggle-label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.toggle-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffd3cc;
    transition: 0.3s;
    border-radius: 30px;
}

.toggle-span:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }
  
.toggle-input:checked + span {
background-color: #ff2400;
}

.toggle-input:checked + span:before {
transform: translateX(29px);
}

.toggle-text  {
    position: absolute;
    top: -25px;
    width: max-content;
    line-height: 30px;
    margin-left: 10px;
    cursor: pointer;
}