body {
  font-family: 'Roboto', sans-serif;
}

.app-container {
  height: 100vh;
}

.nav-logo {
  height: 70px;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.b-1 {
  border: 1px solid;
}

.b-3 {
  border: 3px solid;
}

.color-black{
  color: #000 !important;
}

.color-light-red {
  color: #ff2400 !important;
}

.color-light-green {
  color: #1fe71f !important;
}

.color-grey {
  color: #a19e9ea9 !important;
}

.border-light-red {
  border-color: #ff2400;
}

.input-select {
  padding: 5px;
  border: 2px solid #ff2400;
  border-radius: 5px;
  height: 38px;
  width: 196px;
}

.input-text {
  padding: 5px;
  border: 2px solid #ff2400;
  border-radius: 5px;
  height: 38px;
  width: 196px;
}

.input-text:focus-visible {
  outline: 0px;
  border-color: #ff917f;
}

.input-error {
  color: #ff2400;
  font-size: 13px;
}

.server-error {
  color: #ff2400;
  font-size: 15px;
}

.server-success {
  color: #1fe71f;
  font-size: 15px;
}

.button {
  background-color: #ff2400;
  color: #FFFFFF;
  font-weight: 500;
  border: 1px solid white;
  border-radius: 13px;
  padding: 8px 16px;
  width: fit-content;
}

.button-secondary {
  background-color: white;
  color: #ff2400;
  font-weight: 500;
  border: 1px solid #ff2400;
  border-radius: 13px;
  padding: 8px 16px;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  .button {
    padding: 8px 12px;
    font-size: 12px;
  }
  .button-secondary {
    padding: 8px 12px;
    font-size: 12px;
  }
}

.shadow{
  -webkit-box-shadow: 5px 5px 16px -7px #000000; 
  box-shadow: 5px 5px 16px -7px #000000;
}

.radius-10 {
  border-radius: 10px;
}

.first-uppercase::first-letter {
  text-transform: uppercase;
}

.first-uppercase {
  display:inline-block
}

.cursor-pointer {
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}


.overflow-x-auto {
  overflow-x: auto;
}